import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { catchError, throwError } from 'rxjs';

export const handleErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error) => {
      let errorMessage =
      'Ocurrió un error en la solicitud. Por favor, inténtalo de nuevo más tarde.';
      
      if (error.status === 401) {
        errorMessage = 'No autorizado. Por favor, inicia sesión.';
        authService.logout();
        router.navigate(['/auth/login']);
      }

      return throwError(() => new Error(errorMessage));
    }),
  );
};