import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const token = await this.authService.isLogged();

    if (token) {
      if (state.url === '/auth') {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/auth']);
      return false;
    }
  }
}

export const authGuardCheck: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  return inject(AuthGuard).canActivate(next, state);
};
