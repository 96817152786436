import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const token = authService.getToken();

  if (req.headers.has('AuthorizationRequired')) {
    if (token) {
      const clonedReq = req.clone({
        headers: req.headers.delete('AuthorizationRequired'),
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      return next(clonedReq);
    }
  }

  return next(req);
};
