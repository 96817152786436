import { Routes } from '@angular/router';
import { authGuardCheck } from '@guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'inicio',
    loadChildren: () =>
      import('./pages/home/home.routes').then((r) => r.homeRoutes),
    canActivate: [authGuardCheck],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.routes').then((r) => r.authRoutes),
  },
  //   {
  //     path: '**',
  //     component: NotFoundComponent,
  //   },
];
